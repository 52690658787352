import { twisterType } from 'app/constants/twisterType.constants';
import { CreateTourPlaceType } from 'app/constants/place.constant';
import * as types from '../actions/action_types';

const initialState = {
  data: {},
  dataPoint: {},
  activePoint: {},
  activeMultiPart: {},
  isEditMode: false,
  fetching: false,
  fetched: false,
  activeTourTab: '0',
  error: null,
  id: 0,
  map: {
    lat: 0,
    lng: 0,
  },
  latestResultSearch: [],
  timestamp: 0,
  amountTours: '',
  publicTypeRequiredFiled: {
    title: true,
    points: true,
    img: true,
    desc: true,
  },
  isCorrectionEnabled: true,
  isAutocorrectionEnabled: true,
  isCorrectViaAvailable: true,
  isCorrectDragAvailable: true,
  activePointId: 0,
  isNeedReloadCommonPage: false,
  isFullScreenModeEnabled: false,
  gps: {
    fetching: false,
    fetched: false,
  },
  unpaved: false,
  highway: false,
  ferry: false,
  isShapingVisible: false,
  wasJustImported: false,
  isTtoVisible: false,
  isPlaceVisible: {
    visible: false,
    type: CreateTourPlaceType.My,
  },
  routeSuggestion: [],
  isActiveFreeHand: false,
  activeMode: twisterType.RidePlan,
  isClearTour: false,
};

const getPartPos = (partId) => (partId > 0 ? partId - 1 : 0);

const isCreateTourPage = (payload) =>
  payload.location &&
  payload.location.pathname &&
  payload.location.pathname.indexOf('createtour') > -1;

const tourFullData = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (isCreateTourPage(action.payload)) {
        return state;
      }
      return { ...initialState, wasJustImported: state.wasJustImported };
    }

    case types.TOUR_FULL_DATA_SWITCH_SHAPING_POINTS:
      return { ...state, isShapingVisible: action.payload };
    case types.TOUR_FULL_DATA_SWITCH_TTO_VISIBLE:
      return { ...state, isTtoVisible: action.payload };
    case types.TOUR_FULL_DATA_AVOID_UNPAVED:
      return { ...state, unpaved: action.payload };
    case types.TOUR_FULL_DATA_AVOID_HIGHWAY:
      return { ...state, highway: action.payload };
    case types.TOUR_FULL_DATA_AVOID_FERRY:
      return { ...state, ferry: action.payload };
    case types.TOUR_FULL_DATA_PENDING:
      return { ...state, fetching: true };
    case types.TOUR_FULL_DATA_REJECTED:
      return { ...state, fetching: false, error: action.payload };

    case types.TOUR_FULL_DATA_FULFILLED: {
      if (!action.payload) {
        return {
          ...state,
          fetching: false,
          error: 'The tour was not found',
          data: {},
          activeMultiPart: {},
        };
      }
      let actMultiPart = action.payload;
      let activeMode = twisterType.RidePlan;
      if (
        action.payload &&
        (!action.payload.parts || action.payload.parts.length === 0)
      ) {
        const pointsData = { ...action.payload.pointsData };
        action.payload.parts = [{ ...action.payload, pointsData }];
        delete action.payload.points;
        actMultiPart = action.payload.parts[0]; //eslint-disable-line
      }
      if (
        action.payload &&
        action.payload.user &&
        action.payload.user.avatar &&
        action.payload.user.avatar.smallpath
      ) {
        action.payload.user.avatar = action.payload.user.avatar.smallpath;
      }
      if (action.payload.twister_data?.twister_type) {
        activeMode = action.payload.twister_data.twister_type;
      }

      if (action.payload.twister_data === null) {
        action.payload.twister_data = {
          direction: 0,
          speed_profile: 0,
          is_random: true,
          slider: 0,
          twister_type: twisterType.RidePlan,
        };
        actMultiPart.twister_data = {
          direction: 0,
          speed_profile: 0,
          is_random: true,
          slider: 0,
          twister_type: twisterType.RidePlan,
        };
      }

      if (state.isClearTour) {
        action.payload.twister_data = {
          direction: 0,
          speed_profile: 0,
          is_random: true,
          slider: 0,
          twister_type: action.payload.twister_data.twister_type,
        };
      }
      const {
        unpaved = false,
        highway = false,
        ferry = false,
        isShapingVisible = false,
      } = action.payload;

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: { ...action.payload },
        activeMultiPart: { ...actMultiPart },
        unpaved,
        highway,
        ferry,
        isShapingVisible,
        activeMode,
      };
    }

    case types.TOUR_CLEAR_TOUR_PART: {
      if (state.activeTourTab === '0') {
        return {
          ...state,
          activeMultiPart: { ...action.payload },
          data: { ...action.payload },
        };
      }

      const tourParts = state.data.parts;
      tourParts[+state.activeTourTab - 1] = {
        ...action.payload,
        title: `Part ${+state.activeTourTab}`,
      };

      return {
        ...state,
        activeMultiPart: { ...action.payload },
        data: { ...state.data, parts: tourParts },
      };
    }

    case types.TOUR_FULL_POINT_DATA: {
      return { ...state, dataPoint: action.payload };
    }

    case types.TOUR_FULL_OPENED_MULTI_PART:
      return { ...state, activeTourTab: action.payload };

    case types.TOUR_FULL_SET_ACTIVE_MULTI_PART: {
      let activeMultiPart = {};
      if (state.data.parts) {
        if (typeof action.payload === 'object') {
          if (action.payload.length > 0) {
            // array of points active part
            activeMultiPart = {
              ...state.activeMultiPart,
              points: action.payload.slice(),
            };
            // full active part data
          } else {
            activeMultiPart = { ...action.payload };
          }
        } else {
          // number active part
          const tourParts = state.data.parts.slice();
          activeMultiPart =
            +action.payload === 0 && tourParts && tourParts.length > 1
              ? { ...state.data }
              : { ...tourParts[getPartPos(action.payload)] };
          if (activeMultiPart.twister_data === null) {
            activeMultiPart.twister_data = {
              direction: 0,
              speed_profile: 0,
              is_random: true,
              slider: 0,
              twister_type: twisterType.RidePlan,
            };
          }
        }
      }
      return { ...state, activeMultiPart };
    }

    case types.TOUR_FULL_ACTIVE_MULTI_PART_POINT: {
      return { ...state, activePoint: action.payload };
    }

    case types.TOUR_FULL_DATA_ID: {
      if (action.payload.id && state.data && state.data.parts) {
        const {
          id,
          part,
          share_key: shareKey,
          parts: parts_info,
        } = action.payload;
        if (!part || part === 0) {
          const { parts } = state.data;
          parts.map((part) => {
            if (!part.id) {
              part.id = parts_info[part.part_id];
            }
          });
          return {
            ...state,
            data: { ...state.data, id, share_key: shareKey, parts: [...parts] },
            activeMultiPart: {
              ...state.activeMultiPart,
              id,
              share_key: shareKey,
              parts: [...parts],
            },
          };
        }
        const { parts } = state.data;
        if (parts[+part - 1]) {
          parts[+part - 1].id = id;
          parts[+part - 1].share_key = shareKey;
        }
        const act =
          state.activeMultiPart.part_id === part
            ? { ...state.activeMultiPart, id, share_key: shareKey }
            : { ...state.activeMultiPart };
        return {
          ...state,
          data: { ...state.data, parts: [...parts] },
          activeMultiPart: act,
        };
      }
      return { ...state, id: action.payload };
    }

    case types.TOUR_FULL_DATA_PENDING_DAY: {
      const dataPending = state.data;
      return { ...state, data: { ...dataPending, parts: action.payload } };
    }

    case types.TOUR_FULL_DATA_ADD_NEW_DAY: {
      const dataPending = state.data;

      if (!dataPending.multiDay) dataPending.multiDay = true;

      if (dataPending.parts[0].parent_tour_id === 0)
        dataPending.parts[0].parent_tour_id = +dataPending.id;

      const dataParts = state.data.parts;
      dataParts.push(action.payload);
      return { ...state, data: { ...dataPending, parts: dataParts } };
    }

    case types.TOUR_FULL_DATA_ADD_VIA_DAY: {
      return { ...state, data: { ...state.data, parts: action.payload.parts } };
    }

    case types.TOUR_FULL_SET_ARROW_TYPE: {
      const { arrow_type: arrowType, timestamp } = action.payload;

      return {
        ...state,
        data: { ...state.data, arrow_type: arrowType },
        activeMultiPart: { ...state.activeMultiPart, arrow_type: arrowType },
        timestamp,
      };
    }

    case types.TOUR_FULL_RESAVE: {
      const { timestamp } = action.payload;
      return { ...state, timestamp };
    }

    case types.TOUR_FULL_SET_ACCESSILITY_TYPE: {
      const { newTypes, timestamp } = action.payload;
      const {
        tourType,
        listCheckedFriends,
        listCheckedClubs,
        tourClubId,
        rr_type: rrType,
      } = newTypes;
      return {
        ...state,
        data: {
          ...state.data,
          tourType,
          rrType,
          listCheckedFriends,
          listCheckedClubs,
          tourClubId,
        },
        activeMultiPart: {
          ...state.activeMultiPart,
          tourType,
          listCheckedFriends,
          listCheckedClubs,
          tourClubId,
        },
        timestamp,
      };
    }

    case types.TOUR_FULL_SET_POST_COUNTED_DATA: {
      const { distance, duration, timestamp } = action.payload;
      const newParts = state.data.parts ? [...state.data.parts] : [];
      if (state.activeTourTab !== '0') {
        if (newParts[+state.activeTourTab - 1]) {
          newParts[+state.activeTourTab - 1].distance = distance;
          newParts[+state.activeTourTab - 1].duration = duration;
        }
      }
      return {
        ...state,
        activeMultiPart: { ...state.activeMultiPart, distance, duration },
        data: { ...state.data, parts: newParts },
        timestamp,
      };
    }

    case types.TOUR_FULL_SET_MAIN_PART_DISTANCE_DURATION: {
      const { distance, duration, timestamp } = action.payload;
      return {
        ...state,
        data: { ...state.data, distance, duration },
        timestamp,
      };
    }

    case types.TOUR_FULL_SET_PART_DISTANCE_DURATION_BY_ID: {
      const { distance, duration, partId } = action.payload;
      const { parts = [] } = state.data;
      const newParts = parts.map((part) => {
        if (
          part &&
          !part.distance &&
          !part.duration &&
          part.id === partId &&
          distance
        ) {
          part.distance = distance;
          part.duration = duration;
        }
        return part;
      });
      const data = {
        distance: state.data.distance,
        duration: state.data.duration,
      };
      if (state.data.multiDay === true) {
        data.distance = 0;
        data.duration = 0;
        newParts.forEach((part) => {
          if (part.distance && part.duration) {
            data.distance += part.distance;
            data.duration += part.duration;

            return null;
          }
          part.points.map((point) => {
            if (point.distance) {
              data.distance += point.distance;
            }
            if (point.duration) {
              data.duration += point.duration;
            }

            return null;
          });
          return part;
        });
      }

      if (partId === 0) {
        return {
          ...state,
          activeMultiPart: {
            ...state.activeMultiPart,
            distance,
            duration,
          },
          data: {
            ...state.data,
            parts: newParts,
            distance,
            duration,
          },
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          parts: newParts,
          distance: data.distance,
          duration: data.duration,
        },
        wasJustImported: false,
      };
    }

    case types.SAVE_FIRST_DAY_WHEN_MULTIDAY_ON: {
      const dataParts = [...state.data.parts];
      dataParts[0] = action.payload;

      return { ...state, data: { ...state.data, parts: dataParts } };
    }

    case types.TOUR_FULL_DATA_UPDATE_DAY: {
      const { partPos = 0, partPoints } = action.payload;
      const parts = state.data.parts ? state.data.parts.slice() : [];
      if (parts[partPos] && partPoints.length > 0)
        parts[partPos].points = partPoints;
      const datas = { ...state.data, parts, multiDay: parts.length > 1 };
      if (state.activeTourTab === '0') {
        datas.points = partPoints;
      }
      return {
        ...state,
        data: datas,
        activeMultiPart: { ...state.activeMultiPart, points: partPoints },
      };
    }

    case types.TOUR_FULL_PART_POINTS_DATA_UPDATE: {
      const { partPos = 0, partPointsData } = action.payload;
      const parts = state.data.parts ? state.data.parts.slice() : [];
      if (parts[partPos]) parts[partPos].pointsData = partPointsData;
      const datas = { ...state.data, parts };
      if (state.activeTourTab === '0') {
        datas.pointsData = partPointsData;
      }
      return {
        ...state,
        data: datas,
        activeMultiPart: {
          ...state.activeMultiPart,
          pointsData: partPointsData,
        },
      };
    }

    case types.TOUR_FULL_DATA_UPDATE_DAY_WITH_TIMESTAMP: {
      const { partPos = 0, timestamp, points } = action.payload;
      const parts = state.data.parts ? state.data.parts.slice() : [];
      if (parts[partPos] && points.length > 0) parts[partPos].points = points;
      return {
        ...state,
        data: {
          ...state.data,
          parts,
          multiDay: parts.length > 1,
          points: state.activeTourTab === '0' ? points : state.data.points,
        },
        activeMultiPart: { ...state.activeMultiPart, points },
        timestamp,
      };
    }

    case types.TOUR_FULL_DATA_DELETE_DAY: {
      const dataPending = state.data;
      const dataParts = state.data.parts;
      const partId = action.payload;
      if (dataParts[partId]) {
        const tmpDistance = dataParts[partId].distance;
        const tmpDuration = dataParts[partId].duration;
        dataPending.distance -= tmpDistance;
        dataPending.duration -= tmpDuration;
      }

      dataParts.splice(partId, 1);
      if (!+state.activeTourTab && dataParts.length > 1) {
        return { ...state, data: { ...dataPending, parts: dataParts } };
      }
      const activeMultiPart =
        dataParts && dataParts.length === 1
          ? { ...state.data }
          : { ...dataParts[getPartPos(partId)] };

      return {
        ...state,
        activeTourTab:
          dataParts && dataParts.length === 1
            ? '0'
            : `${partId > 0 ? partId : 1}`,
        data: { ...dataPending, parts: dataParts },
        activeMultiPart,
      };
    }

    case types.TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR: {
      const dataPending = state.data;
      return { ...state, data: { ...dataPending, multiDay: action.payload } };
    }

    case types.TOUR_FULL_IS_EDIT_MODE:
      return { ...state, isEditMode: action.payload.isEditMode };

    case types.USER_TOUR_AMOUNT_FULFILLED: {
      const { amount, isClear } = action.payload;
      let amountTours = amount;
      if (amountTours > 0) {
        if (+state.activeMultiPart > 0) {
          const parts = [...state.data.parts];
          parts[+state.activeMultiPart - 1] = {
            ...parts[+state.activeMultiPart - 1],
            title: `Part ${+state.activeMultiPart + 1}`,
          };
          return {
            ...state,
            data: {
              ...state.data,
              parts,
            },
            activeMultiPart: {
              ...state.activeMultiPart,
              title: `Part ${+state.activeMultiPart + 1}`,
            },
          };
        }
        amountTours = isClear ? +amountTours : +amountTours + 1;
        return {
          ...state,
          data: { ...state.data, title: `Tour ${amountTours}` },
          activeMultiPart: {
            ...state.activeMultiPart,
            title: `Tour ${amountTours}`,
          },
          amountTours,
        };
      }
      return state;
    }

    case types.USER_TOUR_AMOUNT_COPY_FULFILLED: {
      const amountTours = action.payload.amount;
      if (amountTours > 0) {
        return {
          ...state,
          data: { ...state.data, title: `Tour ${action.payload.title}` },
          activeMultiPart: {
            ...state.activeMultiPart,
            title: `Tour ${action.payload.title}`,
          },
          amountTours,
        };
      }
      return state;
    }

    case types.TOUR_FULL_DATA_MAP_POSITION: {
      const { lat, lng, zoom: mapZoom } = action.payload;
      return {
        ...state,
        data: { ...state.data, lat, lng, map_zoom: mapZoom },
        activeMultiPart: {
          ...state.activeMultiPart,
          lat,
          lng,
          map_zoom: mapZoom,
        },
      };
    }

    case types.TOUR_FULL_DATA_LAST_SEARCH_RESULT: {
      const data = state.latestResultSearch;
      data.map((item) => {
        if (item.value === action.payload.value) {
          data.splice(data.indexOf(item), 1);
        }
        return item;
      });
      if (data.length < 4) {
        data.push(action.payload);
      } else if (data.length >= 4) {
        data.shift();
        data.push(action.payload);
      }
      return {
        ...state,
        latestResultSearch: [...data],
      };
    }

    case types.TOUR_FULL_INFO_UPDATE_DAY: {
      const { newDayData, timestamp } = action.payload;
      const updateDayDataParts = state.data.parts;

      if (updateDayDataParts)
        updateDayDataParts[state.activeTourTab - 1] = newDayData;

      if (state.data.multiDay === false) {
        return {
          ...state,
          activeMultiPart: { ...newDayData },
          data: { ...newDayData, parts: updateDayDataParts },
          timestamp,
        };
      }
      if (state.activeTourTab === '0') {
        return {
          ...state,
          activeMultiPart: newDayData,
          data: newDayData,
          timestamp,
        };
      }

      return {
        ...state,
        activeMultiPart: newDayData,
        data: { ...state.data, parts: updateDayDataParts },
        timestamp,
      };
    }

    case types.TOUR_FULL_DATA_ADD_OWNER:
      return {
        ...state,
        data: { ...state.data, user: action.payload },
        activeMultiPart: { ...state.activeMultiPart, user: action.payload },
      };

    case types.LANGUAGE_DATA_CHANGE_ACTIVE: {
      // TODO Fixed problem with points when change language
      const data = { ...state.data };
      const activeMultiPart = { ...state.activeMultiPart };
      data.parts = null;
      activeMultiPart.parts = null;
      return {
        ...state,
        data,
        activeMultiPart,
      };
    }

    case types.TOUR_FULL_DATA_COPY_ID:
      if (action.payload.id) {
        if (!action.payload.part || action.payload.part === 0) {
          return {
            ...state,
            data: { ...state.data, id: action.payload.id },
            activeMultiPart:
              +state.activeTourTab === +action.payload.part
                ? { ...state.activeMultiPart, id: action.payload.id }
                : { ...state.activeMultiPart },
          };
        }
        const { parts } = state.data;
        parts[+action.payload.part - 1].id = action.payload.id;
        return {
          ...state,
          data: { ...state.data, parts: [...parts] },
          activeMultiPart:
            +state.activeTourTab === +action.payload.part
              ? { ...state.activeMultiPart, id: action.payload.id }
              : { ...state.activeMultiPart },
        };
      }
      return { ...state, id: action.payload };

    case types.PUBLIC_TOUR_TYPE_REQUIRED_FILED:
      return { ...state, publicTypeRequiredFiled: action.payload };

    case types.SET_CORRECTION_WRONG_ROUTE_STATUS:
      return { ...state, isCorrectionEnabled: action.payload };

    case types.SET_AUTO_CORRECTION_TO_ROUTE_STATUS: {
      return { ...state, isAutocorrectionEnabled: action.payload };
    }

    case types.SET_VIA_CORRECTION_STATUS: {
      return { ...state, isCorrectViaAvailable: action.payload };
    }

    case types.SET_DRAG_CORRECTION_STATUS: {
      return { ...state, isCorrectDragAvailable: action.payload };
    }

    case types.SET_OPTIMIZE_TOUR_SETTINGS: {
      const getValue = (key) => {
        const option = action.payload[key];
        if (option) {
          const obj = typeof option === 'string' ? JSON.parse(option) : option;
          if ({}.hasOwnProperty.call(obj, 'status')) return !!obj.status;
        }
        return false;
      };

      const newState = {
        ...state,
        isCorrectionEnabled: getValue(types.SET_CORRECTION_WRONG_ROUTE_STATUS),
        isAutocorrectionEnabled: getValue(
          types.SET_AUTO_CORRECTION_TO_ROUTE_STATUS
        ),
        isCorrectViaAvailable: getValue(types.SET_VIA_CORRECTION_STATUS),
        isCorrectDragAvailable: getValue(types.SET_DRAG_CORRECTION_STATUS),
        unpaved: getValue(types.TOUR_FULL_DATA_AVOID_UNPAVED),
        highway: getValue(types.TOUR_FULL_DATA_AVOID_HIGHWAY),
        ferry: getValue(types.TOUR_FULL_DATA_AVOID_FERRY),
        isShapingVisible: getValue(types.TOUR_FULL_DATA_SWITCH_SHAPING_POINTS),
        isTtoVisible: getValue(types.TOUR_FULL_DATA_SWITCH_TTO_VISIBLE),
      };
      return newState;
    }

    case types.TOUR_FULL_SELECT_POINT:
      return { ...state, activePointId: action.payload };

    case types.TOUR_FULL_OPTIMIZE_SAVE:
      return { ...state, data: action.payload, isNeedReloadCommonPage: true };

    case types.TOUR_FULL_GPS_DATA_LODING_STATUS: {
      const { fetching, fetched } = action.payload;
      return { ...state, gps: { fetching, fetched } };
    }

    case types.TOUR_FULL_DISABLE_RELOAD_COMMON_PAGE:
      return { ...state, isNeedReloadCommonPage: false };

    case types.SET_FULL_SCREEN_MODE_STATUS:
      return { ...state, isFullScreenModeEnabled: action.payload };

    case types.TOUR_FULL_DATA_SAVE_PREV_PART_POINT: {
      const { prevPartPos = 0, prevPartPoints } = action.payload;
      const prevParts = state.data.parts ? state.data.parts.slice() : [];

      if (prevParts[prevPartPos])
        prevParts[prevPartPos].points = prevPartPoints;

      return {
        ...state,
        data: { ...state.data, parts: prevParts },
      };
    }

    case types.TOUR_FULL_CHANGE_PART_ID: {
      return {
        ...state,
        data: { ...state.data, parts: action.payload.parts },
        activeMultiPart: {
          ...state.activeMultiPart,
          part_id: action.payload.part_id,
        },
      };
    }

    case types.TOUR_FULL_DATA_IMPORTED: {
      return {
        ...state,
        wasJustImported: true,
      };
    }

    case types.TOUR_FULL_ROUTE_SUGGESTION: {
      return { ...state, routeSuggestion: action.payload };
    }

    case types.TOUR_FULL_UPDATE_MAIN_PART_TITLE: {
      if (state.data.multiDay === false) {
        return {
          ...state,
          activeMultiPart: { ...state.activeMultiPart, title: action.payload },
          data: { ...state.data, title: action.payload },
        };
      }
      if (state.activeTourTab === '0') {
        return {
          ...state,
          data: { ...state.data, title: action.payload },
          activeMultiPart: { ...state.activeMultiPart, title: action.payload },
        };
      }
      return { ...state, data: { ...state.data, title: action.payload } };
    }

    case types.TOUR_FULL_ACTIVE_MODE:
      return { ...state, activeMode: action.payload };

    case types.TOUR_FULL_SET_FREE_HAND:
      return { ...state, isActiveFreeHand: action.payload };

    case types.TOUR_FULL_IS_CLEAR_TOUR:
      return { ...state, isClearTour: action.payload };

    case types.TOUR_FULL_SET_TWISTER_DATA: {
      const newParts = state.data.parts ? [...state.data.parts] : [];
      if (state.activeTourTab !== '0') {
        if (newParts[+state.activeTourTab - 1]) {
          newParts[+state.activeTourTab - 1].twister_data = {
            ...newParts[+state.activeTourTab - 1].twister_data,
            ...action.payload,
          };
        }
      }
      if (state.activeTourTab === '0') {
        return {
          ...state,
          activeMultiPart: {
            ...state.activeMultiPart,
            twister_data: {
              ...state.activeMultiPart.twister_data,
              ...action.payload,
            },
          },
          data: {
            ...state.data,
            twister_data: {
              ...state.activeMultiPart.twister_data,
              ...action.payload,
            },
          },
        };
      }

      return {
        ...state,
        activeMultiPart: {
          ...state.activeMultiPart,
          twister_data: {
            ...state.activeMultiPart.twister_data,
            ...action.payload,
          },
        },
        data: { ...state.data, parts: newParts },
      };
    }

    case types.TOUR_FULL_DATA_SWITCH_PLACE_DATA: {
      const { type, data } = action.payload;
      return {
        ...state,
        isPlaceVisible: { ...state.isPlaceVisible, [type]: data },
      };
    }

    case types.TOUR_FULL_DATA_SET_DESCRIPTION: {
      const { description } = action.payload;
      const newParts = state.data.parts ? [...state.data.parts] : [];
      if (state.activeTourTab !== '0') {
        if (newParts[+state.activeTourTab - 1]) {
          newParts[+state.activeTourTab - 1].description = description;
        }
      }
      if (state.activeTourTab === '0') {
        return {
          ...state,
          activeMultiPart: {
            ...state.activeMultiPart,
            description,
          },
          data: {
            ...state.data,
            description,
          },
        };
      }

      return {
        ...state,
        activeMultiPart: {
          ...state.activeMultiPart,
          description,
        },
        data: { ...state.data, parts: newParts },
      };
    }

    default:
      return state;
  }
};

export default tourFullData;
